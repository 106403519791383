var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{attrs:{"total":_vm.total,"query-info":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"reload-table":_vm.renderTable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('view-search-form',{ref:"searchFormRef",attrs:{"queryInfo":_vm.queryInfo,"tool-list":['keyword']},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"on-search":function($event){return _vm.renderTable(1)}}},[_c('el-form-item',{attrs:{"label":"黑名单在列","label-width":"100px"}},[_c('el-select',{attrs:{"clearable":"","size":"small"},model:{value:(_vm.queryInfo.passFlag),callback:function ($$v) {_vm.$set(_vm.queryInfo, "passFlag", $$v)},expression:"queryInfo.passFlag"}},_vm._l((_vm.passFlagList),function(ref){
var value = ref.value;
var label = ref.label;
return _c('el-option',{key:value,attrs:{"label":label,"value":value}})}),1)],1),_c('el-form-item',{attrs:{"label":"用户类型"}},[_c('el-select',{attrs:{"clearable":"","size":"small"},model:{value:(_vm.queryInfo.userType),callback:function ($$v) {_vm.$set(_vm.queryInfo, "userType", $$v)},expression:"queryInfo.userType"}},[_c('el-option',{attrs:{"value":2,"label":"学生"}}),_c('el-option',{attrs:{"value":3,"label":"教职工"}})],1)],1)],1)]},proxy:true}])},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"prop":"stuName","label":"姓名","width":"220"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.stuName || row.teaName)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"stuName","label":"用户类型","min-width":"88","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.userType === 2)?_c('el-tag',[_vm._v("学生")]):_vm._e(),(row.userType === 3)?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("教职工")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"联系电话","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.fixedPhone || row.teaPhone)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"部门/专业班级","min-width":"260"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.className)?[_vm._v(" "+_vm._s(row.campusName)+_vm._s(row.majorName)+_vm._s(row.className)+" ")]:[_vm._v(" "+_vm._s(row.teaDeptName || row.teaCollegeName)+" ")]]}}])}),_c('el-table-column',{attrs:{"prop":"createTime","label":"移入黑名单时间","width":"180","align":"center"}}),_c('el-table-column',{attrs:{"prop":"updateTime","label":"解除时间","width":"180","align":"center"}}),_c('el-table-column',{attrs:{"label":"备注","prop":"remarks","min-width":"200"}}),_c('el-table-column',{attrs:{"label":"操作","width":"130","align":"center","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.passFlag)?[_c('span',{staticClass:"font-grey"},[_vm._v("--已移出黑名单--")])]:[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['gym:unblock']),expression:"['gym:unblock']"}],attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.unBlock(row)}}},[_vm._v("移出黑名单 ")])]]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }